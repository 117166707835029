import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Modal, Table, Form, Button, Dropdown } from "react-bootstrap";
// import "./../assets/scss/customer-select-modal.scss";
import useDebounce, {
  useIsAdmin,
  useIsSales,
  useIsSalesManager,
} from "../../utils/hooks";
import { services } from "../../config";
import queryString from "query-string";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { queryActions } from "../../utils/reactQueryActions";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import {
  copyText,
  customerFullName,
  formatDate,
  paginationOptions,
} from "../../utils/helpers";
import currency from "currency.js";
import { useAuth } from "../../hooks/useAuth";
import CachedIcon from "mdi-react/CachedIcon";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { parse, format } from "date-fns";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import { DeleteIcon, EditIcon, LinkVariantIcon, RecieptIcon } from "../Icons";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmDialog from "../ConfirmDialogue";
import ModalLoader from "../utils/ModalLoader";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { useStoreState } from "easy-peasy";

const options = [
  { label: "Pending", value: "Pending" },
  { label: "Approved", value: "Approved" },
  { label: "Invoiced", value: "Invoiced" },
  { label: "Pre-order", value: "Pre-order" },
  { label: "Disapproved", value: "Disapproved" },
];

export default function PermitModal({
  setShowPermitModal,
  handleSelectedPermit,
  batchData = false,
  Cust_ID = "",
}) {
  const { backendUrl, user: authUser } = useAuth();
  const queryClient = useQueryClient();
  const isAdmin = useIsAdmin();
  const isSalesManager = useIsSalesManager();
  const generalSettings = useStoreState((state) => state.generalSettings);
  const isSales = useIsSales();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    Status: "Approved",
    batchData,
    Warranty: 0,
    UserName: "",
    customerId: Cust_ID,
    ShipTo: "",
  });
  const debouncedqueryParams = useDebounce(queryParams, 500);

  const getPermits = async (debouncedqueryParams, authUser, isSales) => {
    let response = await fetch(
      `${backendUrl}/api/permits/all?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    data.sentByUsers = data?.sentByUsers.map((el) => ({
      ...el,
      label: el.UserName,
      value: el.UserName,
    }));
    return data;
  };

  const { data = { permits: [] }, refetch } = useQuery(
    [queryActions.ALL_PERMITS, debouncedqueryParams, authUser, isSales],
    () => getPermits(debouncedqueryParams, authUser, isSales),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      page: 1,
      [e.target.name]: e.target.value,
    });
  };

  const clearDateRange = () => {
    setQueryParams({
      ...queryParams,
      startDate: "",
      endDate: "",
    });
  };

  const filterByDateRange = (date) => {
    setQueryParams({
      ...queryParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const deletePermitApi = async (payload) => {
    let response = await fetch(`${backendUrl}/api/permits/delete`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const deletePermitMutation = useMutation(
    (payload) => deletePermitApi(payload),
    {
      onSuccess: ({ status, transactionIds }) => {
        refetch();
        toast.success(`Success`);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const deletePermit = async (el) => {
    if (
      await ConfirmDialog({
        title: "Delete",
        description: "Are you sure, you want to delete",
      })
    ) {
      deletePermitMutation.mutate({
        transactionIds: [el.TransactionID],
      });
    }
  };

  return (
    <>
      <Modal
        show={true}
        onHide={() => setShowPermitModal(false)}
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="h5">
              Approval{" "}
              <button
                onClick={() => refetch()}
                className="btn text-primary mx-3"
              >
                <CachedIcon />
              </button>
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="search-area d-flex justify-content-between align-items-center gap-3 w-100 px-3 mb-4">
            <div className="global-search-area  flex-grow-1">
              <MagnifyIcon />
              <Form.Control
                id="queryParams-q"
                className=""
                name="q"
                value={queryParams.q}
                onChange={(e) => handleSearchQueryChange(e)}
                placeholder="Search Trans. ID..."
              />
            </div>

            <Form.Group>
              <Select
                classNamePrefix="form-select"
                placeholder="Status"
                isSearchable={false}
                onChange={({ value }) =>
                  setQueryParams({
                    ...queryParams,
                    page: 1,
                    Status: value,
                  })
                }
                value={options.find((el) => el.value === queryParams.Status)}
                options={options}
              />
            </Form.Group>
          </div>
          <div className="px-3 d-flex gap-3">
            <RsDateRangePicker
              placement="bottomEnd"
              defaultValue={
                queryParams.startDate && queryParams.endDate
                  ? [
                      parse(queryParams.startDate, "yyyy-MM-dd", new Date()),
                      parse(queryParams.endDate, "yyyy-MM-dd", new Date()),
                    ]
                  : []
              }
              onClean={() => clearDateRange()}
              onOk={(date) => filterByDateRange(date)}
            />

            <Form.Group className="text-nowrap col-3">
              <Select
                classNamePrefix="form-select"
                className="text-nowrap"
                placeholder="Sent By"
                isSearchable={false}
                onChange={({ value }) =>
                  setQueryParams({
                    ...queryParams,
                    page: 1,
                    UserName: value,
                  })
                }
                value={data?.sentByUsers?.find(
                  (el) => el.value === queryParams.UserName
                )}
                options={data?.sentByUsers || []}
              />
            </Form.Group>

            {generalSettings?.invoicingType === "Point of Sale" && (
              <Form.Group className="form-mb align-items-center d-flex">
                <Form.Label className="mb-1 text-nowrap fw-bold">
                  Table No.
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Table No."
                  name="ShipTo"
                  value={queryParams.ShipTo}
                  onChange={(e) => handleSearchQueryChange(e)}
                />
              </Form.Group>
            )}
          </div>
          <hr />

          <div className="content px-3 pb-4 pe-2">
            <Table borderless striped responsive className="product-table">
              <thead className="sticky border-bottom">
                <tr>
                  <th />
                  <th>Trans. ID</th>
                  {generalSettings?.invoicingType === "Point of Sale" && (
                    <th>Table No.</th>
                  )}
                  <th>Business Name</th>
                  <th>Total Value</th>
                  <th>Status</th>
                  <th>Sent By</th>
                  <th>Date</th>
                </tr>
              </thead>

              <tbody className="blue-hover">
                {data.permits &&
                  data.permits.map((el, index) => (
                    <tr
                      key={index}
                      className={`p-cursor`}
                      onClick={(e) =>
                        e.detail === 2 && handleSelectedPermit(el)
                      }
                    >
                      <td>
                        {" "}
                        <Dropdown style={{ margin: 0 }}>
                          <Dropdown.Toggle
                            variant=""
                            className="text-primary"
                            bsPrefix="print more"
                          >
                            <DotsVerticalIcon />
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            popperConfig={{
                              strategy: "fixed",
                            }}
                            renderOnMount
                            className="dropdown-with-icons"
                          >
                            <Dropdown.Item
                              as="button"
                              onClick={() => handleSelectedPermit(el)}
                            >
                              <RecieptIcon />
                              Select
                            </Dropdown.Item>
                            {isSalesManager || isAdmin ? (
                              <>
                                <Dropdown.Item as="div">
                                  <Link
                                    to={`/approval/details/${el.TransactionID}`}
                                  >
                                    <EditIcon /> Edit
                                  </Link>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  as="button"
                                  onClick={() => deletePermit(el)}
                                >
                                  <DeleteIcon /> Delete
                                </Dropdown.Item>
                              </>
                            ) : null}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      <td>{el.TransactionID}</td>
                      {generalSettings?.invoicingType === "Point of Sale" && (
                        <td>{el?.ShipTo}</td>
                      )}
                      <td>
                        {el?.customer?.LastName === "Walk-In" && el?.ShipTo
                          ? `${el?.ShipTo} (Walk-In)`
                          : customerFullName(el.customer)}{" "}
                      </td>
                      <td>
                        {currency(el.totalValue, {
                          symbol: "",
                        }).format()}
                      </td>
                      <td>{el.Status}</td>
                      <td>{el.UserName}</td>
                      <td className="text-nowrap">
                        {el?.Date_Log ? formatDate(el.Date_Log) : "..."}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>

          <div className="d-flex justify-content-between px-3 align-items-center pagination">
            <p className="m-0">
              Showing {data?.startIndex + 1} to{" "}
              {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
              {data.count} entries
            </p>

            <ReactPaginate
              {...paginationOptions}
              pageCount={Math.ceil(data.count / queryParams.limit)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={0}
              onPageChange={({ selected }) => {
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                setQueryParams({
                  ...queryParams,
                  page: selected + 1,
                });
              }}
              forcePage={queryParams.page - 1}
            />
          </div>
        </Modal.Body>
      </Modal>
      <ModalLoader show={deletePermitMutation.isLoading} />
    </>
  );
}
