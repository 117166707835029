import { useEffect, useCallback, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import { useQuery } from "react-query";
import { useAuth } from "../../hooks/useAuth";
import eventBus from "../../utils/EventBus";
import { useEffectOnce } from "../../utils/hooks";
import { cacheTimes, queryActions } from "../../utils/reactQueryActions";
import ModalLoader from "../utils/ModalLoader";
import { useStoreState } from "easy-peasy";

export default function PreviewFormOnlyComponent({
  setShowFormPreviewModal,
  template,
  setFetchingIframe,
  size,
  canFill,
  showTableCheckbox,
  showSendButton,
  saveForm,
  printForm,
}) {
  const iframeRef = useRef();
  const { backendUrl } = useAuth();
  const currentLoggedInCompany = useStoreState(
    (state) => state.currentLoggedInCompany
  );
  const [isLoadingIframe, setIsLoadingIframe] = useState(true);

  const handleMessage = (message) => {
    //  console.log(message);
    if (message.data.type === "CLOSE") {
      setShowFormPreviewModal(false);
    } else if (message.data.type === "SAVE") {
      saveForm && saveForm(message.data.value);
    }
  };

  useEffectOnce(() => {
    window.addEventListener("message", handleMessage);

    // cleanup this component
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  });

  // fetch data for form
  const getDepartments = async () => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/users/departments-with-users`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const {
    data = { departments: [], staffs: [], jobGrades: [] },
    isFetching,
  } = useQuery(
    [queryActions.GET_USERS_WITH_DEPARTMENTS],
    () => getDepartments(),
    {
      enabled: true,
      keepPreviousData: true,
      cacheTime: cacheTimes.GET_USERS_WITH_DEPARTMENTS,
    }
  );

  const sendSetupData = useCallback(() => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          data: {
            ...data,
            companyData: currentLoggedInCompany,
            template: template ? JSON.parse(template) : null,
            backendUrl,
          },
          settings: { canFill, showTableCheckbox },
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  }, [template, backendUrl, data, canFill, showTableCheckbox]);

  useEffect(() => {
    sendSetupData();
  }, [template, sendSetupData]);

  eventBus.useCustomEventListener(
    "SEND_FILE_TO_IFRAME",
    ({ files, elementId, elementType }) => {
      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.postMessage(
          {
            data: {
              files,
              elementId,
              elementType,
            },
            type: "FILES_FROM_PARENT",
          },
          process.env.REACT_APP_FORM_BUILDER_URL
        );
      }
    }
  );

  const save = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          type: "SUBMIT",
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  const print = () => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        {
          type: "PRINT",
        },
        process.env.REACT_APP_FORM_BUILDER_URL
      );
    }
  };

  return (
    <>
      <div>
        <div className="px-2">
          {!isFetching ? (
            <iframe
              ref={iframeRef}
              id="Frame"
              onLoad={(e) => {
                setIsLoadingIframe(false);
                setFetchingIframe(true);
                sendSetupData();
              }}
              className="w-100"
              title="Form Preview"
              src={`${process.env.REACT_APP_FORM_BUILDER_URL}/form/fill`}
              style={{ height: size }}
            />
          ) : null}
        </div>
        <div className="w-100 p-4 text-center">
          {showSendButton && !isLoadingIframe && (
            <Button
              size="lg"
              className="px-5"
              type="button"
              onClick={() => save()}
            >
              Submit
            </Button>
          )}

          {printForm && (
            <Button
              size="lg"
              className="px-5 mx-4"
              type="button"
              onClick={() => print()}
            >
              Print
            </Button>
          )}
        </div>
      </div>

      <ModalLoader show={isLoadingIframe || isFetching} />
    </>
  );
}
