import { useState } from "react";
import { Tooltip, OverlayTrigger, Dropdown } from "react-bootstrap";

export function Component(props) {
  //given that props.story contains 'PhotoStory' or 'VideoStory'
  const SpecificComponent = props.component;
  return <SpecificComponent {...props} />;
}

export function FormSwitch() {
  return "switch";
}

export function OverlayTooltip({ text, component }) {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      {component}
    </OverlayTrigger>
  );
}

export const HoverDropDown = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleMouseOver = () => {
    setDropdownOpen(true);
  };

  const handleMouseOut = () => {
    setDropdownOpen(false);
  };

  return (
    <Dropdown
      {...props}
      show={dropdownOpen}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {props.children}
    </Dropdown>
  );
};
