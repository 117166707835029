import { Modal, Form, Button, InputGroup } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import "./../assets/scss/new-entity-modal.scss";
import Select from "react-select";
import DatePickerCustomInput from "./utils/DatePickerCustomInput";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import { LockIcon } from "./Icons";
import { useFormik, getIn } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import { useMutation, useQuery } from "react-query";
import { services } from "../config";
import { toast } from "react-toastify";
import LoginDialog from "./LoginDialog";
import { useState } from "react";
import {
  convertToBaseCurrency,
  creditLimitOptions,
  customerTypeOptions,
  didYouKnowOptions,
  nanoid,
} from "../utils/helpers";
import moment from "moment";
import { useAuth } from "../hooks/useAuth";
import CitySelector from "./CitySelector";
import CreatableSelect from "react-select/creatable";
import { uniqBy } from "lodash";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import EyeOffOutline from "mdi-react/EyeOffOutlineIcon";
import RsDateRangePicker from "./utils/RsDateRangePicker";
import ConfirmDialog from "./ConfirmDialogue";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Popover } from "react-tiny-popover";
import ExpensesSelector from "./utils/ExpensesSelector";
import { useStoreState } from "easy-peasy";
import { useCurrencies } from "../utils/hooks";

export default function NewCustomerModal({
  showCreateNewCustomerModal,
  setShowCreateNewCustomerModal,
  setSelectedCustomer,
  refetch,
}) {
  const { backendUrl, user: authUser } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [isAdminAuthenticated, setIsAdminAuthenticated] = useState(false);
  const {
    deploymentCurrencies: currenciesOptions,
    getCurrencySymbol,
  } = useCurrencies();
  const generalSettings = useStoreState((state) => state.generalSettings);

  const formik = useFormik({
    initialValues: {
      Cust_ID: String(Date.now()),
      FirstName: "",
      LastName: "",
      MiddleName: "",
      Email: "",
      PhoneNo1: "",
      DOB: moment(),
      DateLog: moment(),
      CompanyAddress: "",
      ContactAddress: "",
      FollowUpComment: "",
      credit: 0,
      TransType: "Walk In",
      How_Did_Know: "Walk In",
      Product_Int1: "",
      Product_Int2: "",
      customerLoyalty: false,
      state: "",
      LGA: "",
      password: "",
      creditLimitType: "",
      creditLimitStartDate: new Date(),
      creditLimitEndDate: new Date(),
      Group: "",
      ignoreExistingLastName: false,
      openingAccountID: "300",
      openingAccountDescription: "Opening Balance Equity",
      openingAccountType: "OWNER'S EQUITY",
      openingAccountAmount: 0,
      currencyCode: generalSettings.prevailingCurrency,
      conversionAmount: generalSettings.dollarInBaseCurrency,
    },
    validationSchema: yup.object().shape({
      //  FirstName: yup.string().required(),
      LastName: yup.string().required("Business name is required"),
      //  MiddleName: yup.string().required("Lastname is required"),
      Email: yup.string().email(),
      PhoneNo1: yup.string().required(),
      DOB: yup.string().required(), // moment
      DateLog: yup.string().required(), // moment
      // CompanyAddress: yup.string().required("Company Address is required"),
      // ContactAddress: yup.string().required("Contact Address is required"),
      // FollowUpComment: yup.string(),
      // credit: yup.string(),
      Product_Int1: yup.string(),
      Product_Int2: yup.string(),
    }),
    onSubmit: (values) => {
      const { DOB, DateLog } = values;
      if (typeof DOB === "string") {
        formik.setFieldError("DOB", "Invalid date");
      }
      if (typeof DateLog === "string") {
        formik.setFieldError("DateLog", "Invalid date");
      }
      if (typeof DOB === "string" || typeof DateLog === "string") {
        return;
      }

      submit({
        ...values,
        DOB: DOB.format(),
        DateLog: DateLog.format(),
      });
    },
  });

  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/customers/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    data.customerTypes = uniqBy(
      [
        ...customerTypeOptions,
        ...data.customerTypes
          .filter((el) => el.TransType)
          .map((el) => ({
            value: el.TransType,
            label: el.TransType,
          })),
      ],
      "value"
    );

    data.groups = uniqBy(
      [
        ...data.groups
          .filter((el) => el.Group)
          .map((el) => ({
            value: el.Group,
            label: el.Group,
          })),
      ],
      "value"
    );

    return data;
  };

  const { data = { customerTypes: [] }, isFetching } = useQuery(
    ["CUSTOMER_SETUP"],
    () => fetchSetUpData(),
    {
      keepPreviousData: true,
    }
  );

  const createCustomer = async (payload) => {
    payload.authUser = authUser;

    let dataToConvert = {
      openingAccountAmount: payload.openingAccountAmount,
      currencyCode: payload.currencyCode,
      conversionAmount: payload.conversionAmount,
    };

    if (
      dataToConvert.currencyCode &&
      dataToConvert.currencyCode !== generalSettings?.prevailingCurrency
    ) {
      dataToConvert = convertToBaseCurrency({
        data: dataToConvert,
        conversionAmount: dataToConvert.conversionAmount,
      });
    }

    payload = { ...payload, ...dataToConvert };

    let response = await fetch(`${backendUrl}/api/customers/create`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      if (response.errors) formik.setErrors(response.errors);
      if (response.lastNameExists) {
        confirmDuplicate();
      }
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createCustomerMutation = useMutation(
    (payload) => createCustomer(payload),
    {
      onSuccess: ({ message, data }) => {
        toast.success(message);

        if (setSelectedCustomer) setSelectedCustomer(data.customer);
        formik.resetForm();
        if (refetch) refetch();
        setShowCreateNewCustomerModal(false);
      },
      onError: ({ message }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const confirmDuplicate = async () => {
    if (
      await ConfirmDialog({
        title: `There is already a customer with this Business Name - ${
          formik.values.LastName
        }`,
        description: "Continue ?",
      })
    ) {
      formik.setFieldValue("ignoreExistingLastName", true);
      formik.submitForm();
    }
  };

  const submit = (payload) => {
    createCustomerMutation.mutate(payload);
  };

  const authenticateAdmin = async () => {
    if (await LoginDialog()) {
      setIsAdminAuthenticated(true);
    }
  };

  // GA ===============================================================
  const [showJournalPopover, setShowJournalPopover] = useState(false);
  const handleSelectedAccount = (account) => {
    console.log(account);
    formik.setFieldValue("openingAccountID", account?.AccountID);
    formik.setFieldValue("openingAccountDescription", account?.Description);
    formik.setFieldValue("openingAccountType", account?.Type);
    setShowJournalPopover(false);
  };
  // ===================================================================

  return (
    <Modal
      show={showCreateNewCustomerModal}
      onHide={() => setShowCreateNewCustomerModal(false)}
      dialogClassName="new-entity-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Create New Customer</h1>
          <p>Create a new customer by filling in the following forms.</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          onSubmit={formik.handleSubmit}
          className="row pb-2"
          autoComplete="off"
        >
          <div className="col-md-6">
            <h2 className="mb-3 pb-1">Customer Information</h2>

            <Form.Group className="mb-2">
              <Form.Label className="mb-2">Customer/Inquirer ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="xxxxxx"
                name="Cust_ID"
                value={formik.values.Cust_ID}
                // style={{ pointerEvents: "none" }}
                onChange={formik.handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Label className="mb-2">Customer Type</Form.Label>
              {/* <div className="d-flex gap-3 justify-content-between">
                <Form.Check
                  inline
                  label="Walk In"
                  value="Walk In"
                  name="TransType"
                  type="radio"
                  checked={formik.values.TransType === "Walk In"}
                  onChange={formik.handleChange}
                />

                <Form.Check
                  inline
                  label="Wholesaler"
                  value="Wholesaler"
                  name="TransType"
                  type="radio"
                  checked={formik.values.TransType === "Wholesaler"}
                  onChange={formik.handleChange}
                />

                <Form.Check
                  inline
                  label="Distributor"
                  value="Distributor"
                  name="TransType"
                  type="radio"
                  checked={formik.values.TransType === "Distributor"}
                  onChange={formik.handleChange}
                />
              </div> */}

              <Select
                classNamePrefix={`form-select`}
                options={data.customerTypes}
                value={data?.customerTypes?.find(
                  (el) => el.value === formik.values.TransType
                )}
                onChange={({ value }) =>
                  formik.setFieldValue("TransType", value)
                }
                isLoading={isFetching}
              />
            </Form.Group>

            <Form.Group className="mb-3 pb-2">
              <Form.Label className="mb-1">Business Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter business name"
                name="LastName"
                value={formik.values.LastName}
                onChange={formik.handleChange}
                isInvalid={formik.touched.LastName && !!formik.errors.LastName}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.LastName}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="row">
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter first name"
                  name="FirstName"
                  value={formik.values.FirstName}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.FirstName && !!formik.errors.FirstName
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.FirstName}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter last name"
                  name="MiddleName"
                  value={formik.values.MiddleName}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.MiddleName && !!formik.errors.MiddleName
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.MiddleName}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className="row">
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Email Address</Form.Label>
                <Form.Control
                  className=""
                  type="email"
                  placeholder="Enter email"
                  name="Email"
                  value={formik.values.Email}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.Email && !!formik.errors.Email}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Phone Number</Form.Label>
                <Form.Control
                  className=""
                  type="tel"
                  placeholder="(555) xxxx xxxx"
                  name="PhoneNo1"
                  value={formik.values.PhoneNo1}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.PhoneNo1 && !!formik.errors.PhoneNo1
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.PhoneNo1}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <InputGroup>
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  name="password"
                  placeholder="*****"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.password && !!formik.errors.password
                  }
                />
                {showPassword ? (
                  <InputGroup.Text
                    onClick={() => setShowPassword(false)}
                    className="bg-white"
                  >
                    <EyeOutlineIcon />
                  </InputGroup.Text>
                ) : (
                  <InputGroup.Text
                    onClick={() => setShowPassword(true)}
                    className="bg-white"
                  >
                    <EyeOffOutline />
                  </InputGroup.Text>
                )}
              </InputGroup>

              {formik.errors.password && (
                <span className="text-danger">{formik.errors.password}</span>
              )}
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1" htmlFor="DOB">
                Date of Birth
              </Form.Label>
              <Datetime
                dateFormat="MMM DD"
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside={true}
                name="DOB"
                inputProps={{
                  className: `date-input form-control ${
                    formik.touched.DOB && !!formik.errors.DOB
                      ? "is-invalid"
                      : ""
                  }`,
                  placeholder: "Select date",
                  readOnly: true,
                }}
                value={formik.values.DOB}
                onChange={(date) => {
                  formik.setFieldValue("DOB", date, true);
                }}
                onBlur={() => formik.setFieldTouched("BOB", true)}
              />
              {formik.touched.DOB && !!formik.errors.DOB ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.DOB}
                </span>
              ) : null}
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Customer Address</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter your customer address"
                name="ContactAddress"
                rows={5}
                value={formik.values.ContactAddress}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.ContactAddress &&
                  !!formik.errors.ContactAddress
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.ContactAddress}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="">
              <Form.Label className="mb-1">Company's Address</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter company's address"
                name="CompanyAddress"
                rows={5}
                value={formik.values.CompanyAddress}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.CompanyAddress &&
                  !!formik.errors.CompanyAddress
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.CompanyAddress}
              </Form.Control.Feedback>
            </Form.Group>

            <CitySelector formik={formik} />
          </div>

          <div className="col-md-6 d-flex flex-column justify-content-between">
            <div>
              <h2 className="mt-4 mt-md-0 mb-3  pb-1">Other Information</h2>
              <div className="row">
                <Form.Group className="col-6 mb-3 pb-2">
                  <Form.Label className="mb-1">Customer Log Date</Form.Label>
                  <Datetime
                    dateFormat="MMM DD, YYYY"
                    timeFormat={false}
                    closeOnSelect={true}
                    closeOnClickOutside={true}
                    name="DateLog"
                    inputProps={{
                      className: `date-input form-control ${
                        formik.touched.DateLog && !!formik.errors.DateLog
                          ? "is-invalid"
                          : ""
                      }`,
                      placeholder: "Select date",
                      readOnly: true,
                    }}
                    value={formik.values.DateLog}
                    onChange={(date) => {
                      formik.setFieldValue("DateLog", date, true);
                    }}
                    onBlur={() => formik.setFieldTouched("DateLog", true)}
                  />
                  {formik.touched.DateLog && !!formik.errors.DateLog ? (
                    <span className="custom-invalid-feedback">
                      {formik.errors.DateLog}
                    </span>
                  ) : null}
                </Form.Group>
                <Form.Group className="col-6 mb-3 pb-2">
                  <Form.Label className="mb-1 text-nowrap">
                    How did Know About Us?
                  </Form.Label>
                  <Select
                    classNamePrefix={"form-select"}
                    isSearchable={false}
                    value={didYouKnowOptions.find(
                      (el) => el.value === formik.values.How_Did_Know
                    )}
                    onChange={({ value }) =>
                      formik.setFieldValue("How_Did_Know", value)
                    }
                    options={didYouKnowOptions}
                  />
                  <Form.Control.Feedback type="invalid" />
                </Form.Group>
              </div>
              {/*  <Form.Group className="mb-3 pb-2">
                <Form.Label className="mb-1">If Other Specify</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter last name"
                  name="How_Did_Know"
                  value={formik.values.How_Did_Know}
                  onChange={formik.handleChange}
                />
              </Form.Group> */}
              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Product of Interest</Form.Label>
                <Form.Control
                  className="mb-3"
                  type="text"
                  placeholder="Enter Product of Interest"
                  name="Product_Int1"
                  value={formik.values.Product_Int1}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.Product_Int1 && !!formik.errors.Product_Int1
                  }
                />

                <Form.Control
                  type="text"
                  placeholder="Enter Product of Interest 2"
                  name="Product_Int2"
                  value={formik.values.Product_Int2}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.Product_Int2 && !!formik.errors.Product_Int2
                  }
                />
              </Form.Group>
              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">
                  Follow Up Comment (optional)
                </Form.Label>
                <Form.Control
                  className=""
                  as="textarea"
                  placeholder=""
                  name="FollowUpComment"
                  value={formik.values.FollowUpComment}
                  onChange={formik.handleChange}
                  rows={5}
                />
              </Form.Group>
              <Form.Check
                type="switch"
                label="Activate Customer Loyalty"
                name="customerLoyalty"
                className="custom-form-check mb-3"
                checked={formik.values.customerLoyalty}
                onChange={formik.handleChange}
              />
              <div className="customer-credit">
                <h2 className="mb-1 pb-1"> Customer Credit Limit</h2>

                {!isAdminAuthenticated && (
                  <Button
                    onClick={() => authenticateAdmin()}
                    variant=""
                    type="button"
                    className="text-primary mb-3 pb-1 d-flex align-items-center gap-2"
                  >
                    <LockIcon />

                    <span style={{ opacity: 0.65 }}>Enable Credit Limit</span>
                  </Button>
                )}

                {isAdminAuthenticated && (
                  <>
                    <Form.Group className=" mb-3">
                      <Form.Label className="mb-1">Amount</Form.Label>
                      <CurrencyCustomInput
                        disabled={!isAdminAuthenticated}
                        placeholder="0.00"
                        name="credit"
                        value={formik.values.credit}
                        onValueChange={(value, name) =>
                          formik.setFieldValue(name, value)
                        }
                      />
                    </Form.Group>

                    <Form.Group className=" mb-3">
                      <Form.Label className="mb-1">Type</Form.Label>
                      <Select
                        classNamePrefix={"form-select"}
                        options={creditLimitOptions}
                        value={creditLimitOptions.find(
                          (el) => el.value === formik.values.creditLimitType
                        )}
                        onChange={({ value }) =>
                          formik.setFieldValue("creditLimitType", value)
                        }
                      />
                    </Form.Group>

                    {formik.values.creditLimitType === "Date range" && (
                      <Form.Group className=" mb-3">
                        <Form.Label className="mb-1 w-100">
                          Date Range
                        </Form.Label>
                        <RsDateRangePicker
                          placement="topStart"
                          value={
                            formik.values.creditLimitStartDate &&
                            formik.values.creditLimitEndDate
                              ? [
                                  formik.values.creditLimitStartDate,
                                  formik.values.creditLimitEndDate,
                                ]
                              : []
                          }
                          onClean={() => {
                            formik.setFieldValue("creditLimitStartDate", null);
                            formik.setFieldValue("creditLimitEndDate", null);
                          }}
                          onOk={(date) => {
                            formik.setFieldValue(
                              "creditLimitStartDate",
                              date[0]
                            );
                            formik.setFieldValue("creditLimitEndDate", date[1]);
                          }}
                        />
                      </Form.Group>
                    )}
                  </>
                )}
              </div>
              <hr />
              <Form.Group className="mb-2">
                <Form.Label className="mb-2">Customer Group</Form.Label>
                <Select
                  classNamePrefix={`form-select`}
                  options={data.groups}
                  value={data?.groups?.find(
                    (el) => el.value === formik.values.Group
                  )}
                  onChange={({ value }) => formik.setFieldValue("Group", value)}
                  isLoading={isFetching}
                />
              </Form.Group>
              <hr />
              <h2 className="mb-1 pb-1"> Opening Balance</h2>
              <Form.Group className="mb-3">
                <Form.Label>Account to Credit</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    placeholder="Select Account"
                    value={`${formik.values?.openingAccountID || ""} / ${formik
                      .values?.openingAccountDescription || ""} / ${formik
                      .values?.openingAccountType || ""}`}
                    onChange={() => {}}
                    readOnly
                  />

                  <Popover
                    isOpen={showJournalPopover}
                    onClickOutside={() => setShowJournalPopover(false)}
                    content={() => (
                      <ExpensesSelector
                        handleSelectedExpense={handleSelectedAccount}
                        usage={"chart of accounts"}
                        onHide={() => setShowJournalPopover(false)}
                        Type="OWNER'S EQUITY"
                      />
                    )}
                    position="bottom"
                  >
                    <InputGroup.Text
                      onClick={() => setShowJournalPopover(true)}
                    >
                      <MagnifyIcon />
                    </InputGroup.Text>
                  </Popover>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3 pb-1">
                <Form.Label>Exchange Rate</Form.Label>
                <div className="d-flex gap-3">
                  <Form.Group>
                    <Select
                      classNamePrefix="form-select"
                      placeholder="Select Currency"
                      isSearchable={false}
                      options={currenciesOptions}
                      value={currenciesOptions.find(
                        (el) => el.value === formik.values.currencyCode
                      )}
                      onChange={(selected) =>
                        formik.setFieldValue("currencyCode", selected.value)
                      }
                    />
                  </Form.Group>

                  <Form.Group>
                    <CurrencyCustomInput
                      currencySymbol={"Ex. Rate"}
                      name="conversionAmount"
                      value={formik.values.conversionAmount}
                      onValueChange={(value, name) => {
                        formik.setFieldValue(name, value);
                      }}
                      placeholder="0.00"
                    />
                  </Form.Group>
                </div>{" "}
              </Form.Group>{" "}
              <Form.Group className="mb-3">
                <Form.Label>Opening Account Amount</Form.Label>
                <CurrencyCustomInput
                  currencySymbol={getCurrencySymbol(formik.values.currencyCode)}
                  name="openingAccountAmount"
                  value={formik.values.openingAccountAmount}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  placeholder="0.00"
                />
              </Form.Group>
            </div>

            <Button
              variant="primary"
              type="submit"
              className="w-100 submit-btn mt-3"
              disabled={createCustomerMutation.isLoading}
            >
              {createCustomerMutation.isLoading
                ? "Please wait..."
                : "Create Customer"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
