import { InputGroup, Form, Button, Dropdown } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import queryString from "query-string";
import { toast } from "react-toastify";
import { Popover } from "react-tiny-popover";
import moment from "moment";
import Select from "react-select";

import useDebounce, {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
// import { reportActions } from "../../utils/reactQueryActions";
import {
  ExportIcon,
  FilterTwoIcon,
  FilterCollapseIcon,
  AddCircleIcon,
  CirclesFourIcon,
  TagSimpleIcon,
  DotSpinner,
  SearchIcon,
  NoSelectedItemIcon,
  ExcelIcon,
} from "../Icons";
import "../../assets/scss/reports/cashbook.scss";
import { paginationOptions } from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useAuth } from "../../hooks/useAuth";
import ViewInventryTransit from "../modals/ViewInventryTransit";
import { fetchActionsUtil } from "../../utils/helpers";
import { CustomInput } from "../utils/CustomInput";
import ConfirmDialog from "../ConfirmDialogue";
import TableComponent from "../TableComponent";
import { WarehouseIssueItemModal } from "../modals/WarehouseIssueItemModal";
import WarehouseItemTable from "./WarehouseItemTable";
import ModalLoader from "../utils/ModalLoader";
import ReIssueItemModal from "./ReIssueItem";
import ReturnStorageItemModal from "./ReturnStorageItem";
import { WarehouseSerialIssuerModal } from "../modals/WarehouseSerialIssuer";
import { useStoreState } from "easy-peasy";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import { CSVLink } from "react-csv";

const allStatus = [
  "All",
  "Rejected",
  "Partly Received",
  "Received All",
  "Retured",
  null,
];

export function WarehouseIssuesItem() {
  useScrollTop();
  const initialFilterParams = {
    barCode: "",
    Trans_ID: "",
    startDate: "",
    endDate: "",
    enableDateRange: true,
    serialNumbers: "",
    contracts: "",
    Requester: "",
  };

  const { backendUrl } = useAuth();
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", false);
  const [issueItemModalShow, setIssueItem] = useState(false);
  const [viewItemModalShow, setViewItem] = useState(false);
  const [allIsuesItems, setAllIsuesItems] = useState([]);
  const [selectCompany, setSelectCompany] = useState("All");
  const [loadingId, setLoadingId] = useState();
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [showStatus, setShowStatus] = useState("All");
  const [reIssue, setReIssue] = useState(false);
  const [item, setItem] = useState({});
  const [returnStorageItem, setStorageReturnItem] = useState(false);

  const [showSerialPopover, setShowSerialPopover] = useState(false);
  const [isLoadingVendors, setIsLoadingVendors] = useState(false);
  const [excelData, setExcelData] = useState([]);

  const generalSettings = useStoreState((state) => state.generalSettings);

  const queryClient = useQueryClient();

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);

  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const wareshouseLocal = JSON.parse(
    window.localStorage.getItem("warehouseName")
  );

  const fetchSetUpData = async (queryParams, ware) => {
    setIsLoadingVendors(true);
    let response = await fetch(
      `${backendUrl}/api/warehouse/issuer-setup/${ware}?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    setAllIsuesItems(() => data.issuedItems);

    const warehousesData = await fetchActionsUtil(
      `${backendUrl}/api/warehouse/categories/${wareshouseLocal?.cater}`,
      "GET"
    );

    const jobs = await fetchActionsUtil(`${backendUrl}/api/job`, "GET");

    const plantsData = await fetchActionsUtil(
      `${backendUrl}/api/production/plants`,
      "GET"
    );

    data.jobs = jobs?.data?.jobs?.map((el) => ({
      value: el.jobNumber,
      label: el.title,
    }));

    data.inJobs = [{ label: "None", value: "" }, ...data?.jobs];

    data.staff = data.staffs.map((el) => ({
      value: el.Name,
      label: el.Name,
    }));

    data.changedstaff = data.staffs.map((el) => ({
      value: `${el.Name}-${el.email}`,
      label: el.Name,
    }));

    data.employees = data.employees.map((el) => ({
      value: el.EmployeeID,
      label: `${el.FirstName} ${el.LastName}`,
    }));

    data.locationsMap = [
      {
        value: "",
        label: "All",
      },
      ...data.Alllocation.map((el) => ({
        value: el.locationID,
        label: el.name,
      })),
    ];

    data.locations = data.Alllocation.map((el) => ({
      value: el.locationID,
      label: el.name,
    }));

    data.warehouses = warehousesData?.warehouses
      ?.filter((e) => e.W_ID !== wareshouseLocal?.whID)
      ?.map((el) => ({
        value: el.W_ID,
        label: el.W_name,
      }));

    data.requesters = [
      {
        value: "",
        label: "All",
      },
      ...data.AllWarehouse.map((el) => ({
        value: el?.W_ID,
        label: el?.W_name,
      })),
    ];

    data.plants = plantsData.allPlants.map((el) => ({
      value: `${el[0].Plant_ID}-plant`,
      label: el[0].Plant_Name,
    }));
    setIsLoadingVendors(false);
    return data;
  };

  const {
    data = {
      countItemsIssueLog: 0,
      staff: [],
      requesters: [],
      warehouseActive: {},
    },
    refetch,
    isLoading,
  } = useQuery(
    ["WAREHOUSE_ISSUE_ITEMS", { queryParams, wareshouseLocal }],
    () => fetchSetUpData(queryParams, wareshouseLocal?.whID),
    {
      keepPreviousData: false,
      staleTime: 0,
    }
  );

  const returnItemMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse/return-item/${wareshouseLocal?.cater}`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: ({ message, data }) => {
        refetch();
        toast.success(message);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const handleSelectedItem = (el) => {
    setFilterParams({
      ...filterParams,
      barCode: el.Bar_Code,
    });
    setShowItemsPopover(false);
  };

  const onStatusSelect = (value) => {
    setShowStatus(value);
    console.log(value);

    const filter =
      value !== "Pending"
        ? data?.issuedItems.filter((d) => d.Status === value)
        : data?.issuedItems.filter((d) => d.Status === null);

    if (value === "All") {
      setAllIsuesItems(() => data.issuedItems);
    } else {
      setAllIsuesItems(() => filter);
    }
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const returnItem = async (index, el) => {
    setLoadingId(index);

    if (
      await ConfirmDialog({
        title: "Return Item",
        description:
          "Are you sure you want to return this Item to your inventory",
      })
    ) {
      returnItemMutation.mutate(el);
    }
  };

  const displayTableData = (el, index) => {
    return (
      <>
        <td>{""}</td>
        <td>{el?.Bar_Code}</td>
        <td>
          {wareshouseLocal?.cater === "Assets"
            ? el?.Serial_Number
            : JSON.parse(el?.serialNumbers)?.map((sl) => (
                <span className="mx-2">{sl?.serialNum}</span>
              ))}
        </td>
        <td>{el?.Item_Name}</td>
        <td className="text-center">{el?.Quantity}</td>
        <td className="text-nowrap">
          {el?.WH_Issues_details?.W_name
            ? el?.WH_Issues_details?.W_name
            : el?.Plant_Issues_details?.Plant_Name
            ? el?.Plant_Issues_details?.Plant_Name
            : "Sales"}
          <span className="px-2">- {el?.RequesterName}</span>
        </td>
        <td className="text-center">{el?.QuantityFromReciever}</td>
        <td>
          {`${format(new Date(el?.Date_Log), "yyyy-MM-dd")} ${
            el?.IssueTime?.includes("Z")
              ? format(new Date(el.IssueTime), "HH:mm:ss a")
              : el?.IssueTime
          }`}
        </td>
        <td>
          <div
            className={`px-2 ${
              el.Status === "Rejected"
                ? "view_status_reject"
                : el.Status === "Partly Received"
                ? "view_status_partly"
                : el.Status === "Received All"
                ? "view_status_all"
                : el.Status === "Retured"
                ? "view_status_returned"
                : el.Status === null
                ? "view_status_pending"
                : ""
            }`}
          >
            {el.Status !== null ? el.Status : "Pending"}
          </div>
        </td>
        <td>{el?.Comment}</td>
        {el.Status === "Rejected" && (
          <td>
            {returnItemMutation.isLoading && loadingId === index ? (
              <div>
                <DotSpinner />
                <div id="svg-para" />
              </div>
            ) : (
              <Dropdown>
                <Dropdown.Toggle
                  variant=""
                  className="bg-white border-0"
                  bsPrefix="print more"
                  style={{ height: "5px" }}
                >
                  <DotsHorizontalIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu
                  popperConfig={{
                    strategy: "fixed",
                  }}
                  renderOnMount
                  className="dropdown-with-icons"
                >
                  <Dropdown.Item
                    as="button"
                    onClick={() => returnItem(index, el)}
                  >
                    <TagSimpleIcon className="text-light" />
                    Return to Stock
                  </Dropdown.Item>

                  {/* <Dropdown.Item
                    as="button"
                    // onClick={() => handleDeleteItem(el)}
                  >
                    <CirclesFourIcon />
                    Move to Damages
                  </Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </td>
        )}
      </>
    );
  };

  const tableHead = () => {
    return (
      <thead>
        <tr>
          <th> </th>
          <th>Bar Code</th>
          <th>Serial Number</th>
          <th>Item Name</th>
          <th>Qty Issued</th>
          <th>Reciever</th>
          <th>Reciever Qty</th>
          <th>Date</th>
          <th>Status</th>
          <th>Comment</th>
          <th>Actions</th>
        </tr>
      </thead>
    );
  };

  const tableStorageHead = () => {
    return (
      <thead>
        <tr>
          <th>Bar Code</th>
          <th>Serial Number</th>
          <th>Item Name</th>
          <th>Qty Issued</th>
          <th className="text-center">Reciever</th>
          <th className="text-center">Comment</th>
          <th>Contract</th>
          <th>Returned</th>
          <th className="text-center">Date</th>
          <th className="text-center">Duration in possesion</th>
          <th>Status</th>

          <th>Actions</th>
        </tr>
      </thead>
    );
  };

  const displayStorageTableData = (el, index) => {
    const given = moment(
      format(new Date(el?.Date_Log), "yyyy-MM-dd"),
      "YYYY-MM-DD"
    );
    const current = moment().startOf("day");

    //Difference in number of days
    const duration = moment.duration(given.diff(current)).asDays();

    return (
      <>
        <td>{el?.Bar_Code}</td>
        <td>{el?.Serial_Number}</td>
        <td>{el?.Item_Name}</td>
        <td className="text-center">{el?.Quantity}</td>
        <td className="text-nowrap">
          {el?.WH_Issues_location?.name}
          <span className="px-2">
            {/* - {el?.WH_Issues_employee?.FirstName}{" "}
            {el?.WH_Issues_employee?.LastName} */}
            - {el?.RequesterName}
          </span>
        </td>
        <td>{el?.Comment}</td>
        <td>{el?.jobs_Issuer?.title}</td>
        <td>{el?.QuantityFromReciever}</td>
        <td>
          {`${format(new Date(el?.Date_Log), "yyyy-MM-dd")} ${
            el?.IssueTime?.includes("Z")
              ? format(new Date(el.IssueTime), "HH:mm:ss a")
              : el?.IssueTime
          }`}
        </td>
        <td className="text-center">
          {duration < 0
            ? `${Math.abs(duration)} day(s) ago`
            : duration === 0
            ? `${duration} day`
            : `${duration} day(s) ahead`}
        </td>
        <td>
          <div
            className={`px-2 ${
              el.Status === "Rejected"
                ? "view_status_reject"
                : el.Status === "Received"
                ? "view_status_partly"
                : el.Status === "Retured"
                ? "view_status_all"
                : el.Status === "Partly Retured"
                ? "view_status_returned"
                : el.Status === null
                ? "view_status_pending"
                : ""
            }`}
          >
            {el.Status !== null ? el.Status : "Issued"}
          </div>
        </td>

        {el.Status !== "Retured" && (
          <td>
            <Dropdown>
              <Dropdown.Toggle
                variant=""
                className="bg-white border-0"
                bsPrefix="print more"
                style={{ height: "5px" }}
              >
                <DotsHorizontalIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu
                popperConfig={{
                  strategy: "fixed",
                }}
                renderOnMount
                className="dropdown-with-icons"
              >
                <Dropdown.Item
                  as="button"
                  onClick={() => {
                    setItem(el);
                    setStorageReturnItem(true);
                  }}
                >
                  <TagSimpleIcon className="text-light" />
                  Return to Warehouse
                </Dropdown.Item>

                <Dropdown.Item
                  as="button"
                  onClick={() => {
                    setItem(el);
                    setReIssue(true);
                  }}
                >
                  <CirclesFourIcon />
                  Change Location
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </td>
        )}
      </>
    );
  };

  const handleSerialItem = (el) => {
    setFilterParams({
      ...filterParams,
      serialNumbers: el?.serialNum,
    });
    setShowSerialPopover(false);
  };

  window.addEventListener("storage", () => {
    refetch();
  });

  const DataBody = (exData) => {
    let body = [];

    const given = moment(new Date(exData[0]?.Date_Log), "YYYY-MM-DD");
    const current = moment().startOf("day");

    //Difference in number of days
    const duration = moment.duration(given.diff(current)).asDays();

    if (data?.warehouseActive?.Warehouse_Type !== "Storage") {
      body = exData.map((d) => [
        d.Bar_Code,
        wareshouseLocal?.cater === "Assets"
          ? d?.Serial_Number
          : JSON.parse(d?.serialNumbers)?.map((sl) => `${sl?.serialNum}, `),
        d.Item_Name,
        d.Quantity,
        `${
          d?.WH_Issues_details?.W_name
            ? d?.WH_Issues_details?.W_name
            : d?.Plant_Issues_details?.Plant_Name
            ? d?.Plant_Issues_details?.Plant_Name
            : "Sales"
        } - ${d?.RequesterName}`,
        d?.QuantityFromReciever,
        `${d.Date_Log} ${d.IssueTime}`,
        d.Status !== null ? d.Status : "Pending",
        d.Comment,
      ]);
    } else {
      body = exData.map((d) => [
        d.Bar_Code,
        d?.Serial_Number,
        d.Item_Name,
        d.Quantity,
        `${d?.WH_Issues_location?.name} - ${d?.RequesterName}`,
        d?.Comment,
        d?.el?.jobs_Issuer?.title,
        d?.QuantityFromReciever,
        `${d.Date_Log} ${d.IssueTime}`,
        duration < 0
          ? `${Math.abs(duration)} day(s) ago`
          : duration === 0
          ? `${duration} day`
          : `${duration} day(s) ahead`,
        d.Status !== null ? d.Status : "Issued",
      ]);
    }

    return body;
  };

  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/warehouse/issuer-setup/${
        wareshouseLocal?.whID
      }?&${queryString.stringify(rest)}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;

    exData = exData.data.issuedItems.map((row) => ({
      ...row,
      Date_Log: format(new Date(row.Date_Log), "dd-MMM-yyyy"),
      IssueTime: row?.IssueTime?.includes("Z")
        ? format(new Date(row.IssueTime), "HH:mm:ss a")
        : row?.IssueTime,
    }));

    exData = DataBody(exData);
    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";

    const header =
      data?.warehouseActive?.Warehouse_Type !== "Storage"
        ? [
            "Bar Code",
            "Serial Number",
            "Item Name",
            "Qty Issued",
            "Reciever",
            "Reciever Qty",
            "Date",
            "Status",
            "Comment",
          ]
        : [
            "Bar Code",
            "Serial Number",
            "Item Name",
            "Qty Issued",
            "Reciever",
            "Comment",
            "Contract",
            "Returned",
            "Date",
            "Duration In Possession",
            "Status",
          ];

    exData = [
      [company],
      ["Item Issuser Report"],
      [date],
      [""],
      header,
      ...exData,
      [""],
    ];
    // console.log(exData);
    setExcelData(exData);
  };

  return (
    <section className="cashbook-wrapped">
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className={`filter-area ${showFilter ? "open" : "close"}`}>
              <div className="filter-header">
                <h2>
                  <FilterTwoIcon /> Filters
                </h2>

                <Button
                  variant="white"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <FilterCollapseIcon />
                </Button>
              </div>
              <div className="filter-body">
                <Form>
                  <div className="body">
                    <Form.Group className="mb-2-5">
                      <Form.Label>Bar Code</Form.Label>
                      <div className="input-group mb-3">
                        <InputGroup>
                          <Form.Control
                            name="barCode"
                            placeholder="Enter Bar Code"
                            value={filterParams?.barCode}
                            onChange={(e) => handleFilterParamsChange(e)}
                          />
                          <Popover
                            isOpen={showItemsPopover}
                            onClickOutside={() => setShowItemsPopover(false)}
                            content={() => (
                              <WarehouseItemTable
                                handleSelectedItem={handleSelectedItem}
                              />
                            )}
                            position="bottom"
                          >
                            <InputGroup.Text
                              onClick={() =>
                                setShowItemsPopover(!showItemsPopover)
                              }
                            >
                              <MagnifyIcon />
                            </InputGroup.Text>
                          </Popover>
                        </InputGroup>
                      </div>
                    </Form.Group>

                    {generalSettings.itemsRequiresSerialNumber && (
                      <Form.Group className="mb-2-5">
                        <Form.Label>Serial number</Form.Label>
                        <div className="input-group mb-3">
                          <Popover
                            isOpen={showSerialPopover}
                            onClickOutside={() => setShowSerialPopover(false)}
                            content={() => (
                              <WarehouseItemTable
                                handleSelectedItem={handleSerialItem}
                                serialNumbers
                              />
                            )}
                            position="bottom"
                          >
                            <>
                              <CustomInput
                                className="p-cursor form-control"
                                name="serialNumbers"
                                placeholder="serial number"
                                typeOfInput="text"
                                value={filterParams?.serialNumbers}
                                disabled
                                onChange={(e) => handleFilterParamsChange(e)}
                              />

                              <span
                                className="input-group-text border p-cursor"
                                id="basic-addon1"
                                onClick={() =>
                                  setShowSerialPopover(!showSerialPopover)
                                }
                              >
                                <SearchIcon />
                              </span>
                            </>
                          </Popover>
                        </div>
                      </Form.Group>
                    )}

                    <Form.Group>
                      <Form.Label>Jobs</Form.Label>
                      <Select
                        isMulti={false}
                        closeMenuOnSelect={true}
                        isLoading={isLoadingVendors}
                        isSearchable={true}
                        options={data?.inJobs}
                        value={data?.inJobs?.find(
                          (el) => filterParams?.contracts === el.value
                        )}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            contracts: selected?.value,
                          });
                        }}
                      />
                    </Form.Group>

                    <hr className="mt-3 mb-4" />

                    <div className="mb-2-5">
                      <Form.Check
                        inline
                        label="Enable Date Range"
                        name="enableDateRange"
                        type={"checkbox"}
                        id={`enableDateRange`}
                        defaultChecked={JSON.parse(
                          filterParams?.enableDateRange || false
                        )}
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                    </div>

                    <Form.Group className="">
                      <Form.Label>Date Range</Form.Label>
                      <div className="position-relative">
                        <RsDateRangePicker
                          placement="auto"
                          value={
                            filterParams?.startDate && filterParams.endDate
                              ? [
                                  parse(
                                    filterParams.startDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                  parse(
                                    filterParams.endDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                ]
                              : []
                          }
                          onClean={() => clearDateRange()}
                          onOk={(date) => filterByDateRange(date)}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="buttons rounded">
                    <Button onClick={() => reset()} variant="white">
                      Reset
                    </Button>
                    <Button onClick={() => search()} variant="primary">
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="content-body">
              <header className="text-nowrap">
                <h1>
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  Issued Item History
                  <button
                    title="Refresh Issued Item History"
                    onClick={() => {
                      setSelectCompany("All");
                      refetch();
                    }}
                    className="btn text-primary mx-3"
                  >
                    <CachedIcon />
                  </button>
                  <span>Select Customer : </span>
                  <div className="mx-3 w-100" style={{ zIndex: 999 }}>
                    {data?.warehouseActive?.Warehouse_Type !== "Storage" ? (
                      <Select
                        classNamePrefix={"form-select"}
                        menuPosition="fixed"
                        closeMenuOnSelect={true}
                        isLoading={isLoadingVendors}
                        isSearchable={true}
                        options={data?.requesters}
                        value={data?.requesters?.find(
                          (el) => filterParams?.Requester === el.value
                        )}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            Requester: selected?.value,
                          });
                        }}
                      />
                    ) : (
                      <Select
                        classNamePrefix={"form-select"}
                        menuPosition="fixed"
                        closeMenuOnSelect={true}
                        isLoading={isLoadingVendors}
                        isSearchable={true}
                        options={data?.locationsMap}
                        value={data?.locationsMap?.find(
                          (el) => filterParams?.Requester === el.value
                        )}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            Requester: selected?.value,
                          });
                        }}
                      />
                    )}
                  </div>
                  <span>Select Status : </span>
                  <div className="mx-3 w-100">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      onChange={(e) => onStatusSelect(e.target.value)}
                      value={showStatus}
                    >
                      {allStatus.map((value, i) => (
                        <option value={value}>
                          {value === null ? "Pending" : value}
                        </option>
                      ))}
                    </select>
                  </div>
                </h1>
                <div className="actions">
                  <div
                    className="btn btn-primary"
                    onClick={() => setIssueItem(true)}
                  >
                    Issue Item{" "}
                    <AddCircleIcon color="#FFFFFF" width="" height="" />
                  </div>
                  <CSVLink
                    className="btn print d-none"
                    filename={`Item Issuser Report(${format(
                      new Date(),
                      "dd-MMM-yyyy hh:mm:ss a"
                    )}).csv`}
                    data={excelData}
                    ref={CSVLinkRef}
                  />
                  {/* <div className="btn bg print">
                    Export <ExportIcon className="px-2" />
                  </div> */}

                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="btn print"
                      disabled={isfetchingExcel}
                      bsPrefix=""
                    >
                      Export
                      <ExportIcon color="#008000" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                      className="text-center"
                    >
                      <Dropdown.Item
                        as="div"
                        onClick={onDownloadExcelData}
                        className="p-cursor"
                      >
                        Excel <ExcelIcon color="#008000" />
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </header>

              {data?.warehouseActive?.Warehouse_Type !== "Storage" ? (
                <>
                  <div className="px-md-4">
                    <TableComponent
                      responsive
                      borderless
                      striped
                      tableHeadsFunction={tableHead}
                      mainDataArray={allIsuesItems}
                      tableDataRowFunction={displayTableData}
                      className="product-table text-nowrap"
                    />
                  </div>

                  {allIsuesItems?.length < 1 && (
                    <div className="d-flex justify-content-center">
                      <div className="info text-center">
                        <NoSelectedItemIcon />
                        <h2 className="my-2">No Item Available</h2>
                        <p />
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="px-md-4">
                    <TableComponent
                      responsive
                      borderless
                      striped
                      tableHeadsFunction={tableStorageHead}
                      mainDataArray={allIsuesItems}
                      tableDataRowFunction={displayStorageTableData}
                      className="product-table text-nowrap"
                    />
                  </div>

                  {allIsuesItems?.length < 1 && (
                    <div className="d-flex justify-content-center">
                      <div className="info text-center">
                        <NoSelectedItemIcon />
                        <h2 className="my-2">No Item Available</h2>
                        <p />
                      </div>
                    </div>
                  )}
                </>
              )}
              <div className="d-flex justify-content-between px-3 align-items-center pagination">
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>
                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                    <option value="50">50 rows</option>
                    <option value="100">100 rows</option>
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={Math.ceil(
                    data.countItemsIssueLog / queryParams.limit
                  )}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                  }}
                  forcePage={parseInt(queryParams.page) - 1}
                />
              </div>
            </div>
          </div>
        </div>
        <ModalLoader show={isLoading} />
        {issueItemModalShow && !generalSettings.itemsRequiresSerialNumber && (
          <WarehouseIssueItemModal
            show={issueItemModalShow}
            onHide={() => setIssueItem(false)}
            staffs={data.staff}
            refetchMain={refetch}
            warehouses={data.warehouses}
            plants={data.plants}
            warehouseActive={data?.warehouseActive}
            employees={data?.employees}
            locations={data?.locations}
            contracts={data.jobs}
          />
        )}
        {issueItemModalShow && generalSettings.itemsRequiresSerialNumber && (
          <WarehouseSerialIssuerModal
            show={issueItemModalShow}
            onHide={() => setIssueItem(false)}
            staffs={data.changedstaff}
            refetchMain={refetch}
            warehouses={data.warehouses}
            plants={data.plants}
            warehouseActive={data?.warehouseActive}
            employees={data?.employees}
            locations={data?.locations}
            contracts={data.jobs}
          />
        )}
        {viewItemModalShow && (
          <ViewInventryTransit
            show={viewItemModalShow}
            onHide={() => setViewItem(false)}
          />
        )}

        {reIssue && (
          <ReIssueItemModal
            show={reIssue}
            onHide={() => setReIssue(false)}
            activeItem={item}
            employees={data?.employees}
            locations={data?.locations}
            refetch={refetch}
            contracts={data.jobs}
            staffs={data.staff}
          />
        )}
        {returnStorageItem && (
          <ReturnStorageItemModal
            show={returnStorageItem}
            onHide={() => setStorageReturnItem(false)}
            activeItem={item}
            refetch={refetch}
            warehouseActive={data?.warehouseActive}
          />
        )}
      </main>
    </section>
  );
}
