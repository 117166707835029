import { useCallback, useState } from "react";

import MagnifyIcon from "mdi-react/MagnifyIcon";
import {
  Modal,
  Table,
  Form,
  Button,
  InputGroup,
  FormCheck,
} from "react-bootstrap";
import { appSettings, durationOptions, services } from "../../config";
import useDebounce, {
  useCurrencies,
  useIsAdmin,
  useLocalStorage,
  useUnits,
} from "../../utils/hooks";
import { queryActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/item-select-modal.scss";
import { useQuery } from "react-query";
import currency from "currency.js";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import NumberCustomInput from "../utils/NumberCustomInput";
import queryString from "query-string";

import { useFormik, getIn, FieldArray } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { DeleteIcon, NoSelectedItemIcon } from "../Icons";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useRef } from "react";
import {
  calculateMarkUp_Incentive_Margin,
  paginationOptions,
  pcsToTons,
  qtyFormat,
  qtyFormatToString,
  scrollToElement,
  sortOptions,
  tonsToPcs,
  toTonsOrPcs,
  unitsResolver,
  waitFor,
} from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import { useMemo } from "react";
import { useAuth } from "../../hooks/useAuth";
import { isEmpty, lowerCase } from "lodash";
import { useStoreActions, useStoreState } from "easy-peasy";
import ReactPaginate from "react-paginate";
import ModalLoader from "../utils/ModalLoader";
import { truncate } from "lodash";
import SelectBatchDialog from "./SelectBatchDialog";
import { el } from "date-fns/locale";
import { cloneDeep } from "lodash";
import ConvertQuantity from "../utils/ConvertQuantity";
import noImageUrl from "./../../assets/images/no-image.png";
import foodImageUrl from "./../../assets/images/food.png";
import drinksImageUrl from "./../../assets/images/drinks.png";
import CloseIcon from "mdi-react/CloseIcon";

export default function AddRodItemMultipleGridModal({
  showItemSelectorModal,
  setShowItemSelectorModal,
  handleAddItem,
  saleTypes = [],
  selectedCustomer,
  withQuantity = false,
  previouslySelectedItems = [],
  mode,
  oldInvoiceData,
  isQuotation = false,
  itemsApiUrl = "/api/items",
  Warehouse,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 100,
    q: "",
    batch: true,
    batchData: true,
    withQuantity,
    withItemPriceByCustomerType: true,
    withCategory: true,
    sort: "name-asc",
    restaurantMenu: false,
  });
  const debouncedqueryParams = useDebounce(queryParams, 500);
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl } = useAuth();
  const { Units, getUnitLabelByValue } = useUnits();
  const generalSettings = useStoreState((state) => state.generalSettings);
  const isAdmin = useIsAdmin();
  const [showSelected, setShowSelected] = useState(false);
  const [uiSettings, setUiSettings] = useLocalStorage("uiSettings", {
    addManufacturerToMultipleItemSelect: true,
    addCategoryToMultipleItemSelect: true,
  });
  const { getCurrencySymbol } = useCurrencies();

  /* const initialItem = {
    Quantity: 1,
    UnitPrice: "",
    PriceSold: "",
    durationNumber: 0,
    durationValue: "Month",
    Warranty: false,
    Discount: 0,
    ironRodQuantity: 1,
    saleType: "Tons",
    requirePermission: false,
    Size: "",
  }; */

  const formik = useFormik({
    initialValues: {
      itemsForSale: previouslySelectedItems
        ? previouslySelectedItems.map((el) => {
            return el
              ? {
                  ...el,
                  Quantity:
                    lowerCase(el.saleType) === "tons"
                      ? pcsToTons(el.Quantity, el.Item_Desc, itemMeasurements)
                      : el.Quantity,
                }
              : { ...el };
          })
        : [],
      ShipTo: previouslySelectedItems[0]?.ShipTo,
      saveOrder: false,
    },
    validationSchema: yup.object().shape({}),
    onSubmit: async (values) => {
      try {
        if (isEmpty(values.ShipTo)) {
          formik.setFieldTouched("ShipTo", true);
          formik.setFieldError("ShipTo", "Required");
          toast.error("Enter Table No.");
          return;
        }
        let saveOrder = values.saveOrder;
        let ShipTo = values.ShipTo;
        const { items: itemsWithBatches } = await getBatches(
          values.itemsForSale
        );
        const itemsForSale = cloneDeep(values.itemsForSale).map((item) => {
          const selectedItem = {
            ...item.selectedItem,
            saveOrder,
            ShipTo,
            batches: itemsWithBatches.find(
              (el) => el.Bar_Code === item.Bar_Code
            )?.batches,
          };

          // =======================================
          if (mode === "edit" && !isEmpty(oldInvoiceData)) {
            const foundItem = oldInvoiceData.find(
              (el) => el.Bar_Code === item.Bar_Code
            );
            selectedItem.soldBatches = foundItem?.soldBatches
              ? foundItem?.soldBatches
              : [];

            selectedItem.batches = [
              ...selectedItem.batches.map((el) => {
                // check if batch was sold before
                const foundSoldBatches = selectedItem.soldBatches.filter(
                  (soldbatch) => soldbatch.Bar_Code === el.Bar_Code
                );

                if (!isEmpty(foundSoldBatches)) {
                  const totalSoldQty = foundSoldBatches
                    .map((el) => el.Quantity)
                    .reduce((a, b) => currency(a).add(b).value, 0);
                  el.Quantity = currency(totalSoldQty).add(el.Quantity).value;
                }

                return el;
              }),
            ];

            // it was sold out - 0
            for (let soldbatch of selectedItem.soldBatches) {
              const foundInSelectedBatches = selectedItem.batches.find(
                (el) => el.Bar_Code === soldbatch.Bar_Code
              );
              if (foundInSelectedBatches) {
                continue;
              } else {
                selectedItem.batches.push(soldbatch);
              }
            }
          }
          //============================================================

          const values = cloneDeep(item);

          // Convert to lowest form, to Validate if enough in stock
          //   const originalQuantity = values.Quantity;
          values.Quantity =
            lowerCase(values.saleType) === "tons" && selectedItem?.Item_Desc
              ? tonsToPcs(
                  values.Quantity,
                  selectedItem.Item_Desc,
                  itemMeasurements
                )
              : values.Quantity;

          if (
            lowerCase(selectedItem.Item_Type) !== "service" &&
            !isQuotation &&
            !selectedItem.restaurantMenu
          ) {
            if (Number(values.Quantity) > selectedItem?.quantityInStock) {
              values.error = `${
                selectedItem.Item_Name
              }: Not enough item in stock`;
            }
          }

          values.Warrant_Duration = `${item.durationNumber} ${
            item.durationValue
          }`;

          values.PriceSold = currency(values.PriceSold).value;

          values.SubTotal = currency(values.PriceSold).multiply(
            lowerCase(values.saleType) === "tons"
              ? pcsToTons(
                  values.Quantity,
                  selectedItem.Item_Desc,
                  itemMeasurements
                )
              : values.Quantity
          ).value;

          values.Discount = currency(values.Discount, {
            symbol: "",
            separator: "",
          })
            .multiply(
              lowerCase(values.saleType) === "tons"
                ? pcsToTons(
                    values.Quantity,
                    selectedItem.Item_Desc,
                    itemMeasurements
                  )
                : values.Quantity
            )
            .format();

          // Get Profit Based on batch we are picking from---------------------------------------------------
          const quantityToSell = values.Quantity;
          let remainder = quantityToSell;
          const updatedBatchDataToSave = [];
          let measurement = itemMeasurements.find(
            (measurement) => measurement.Size === selectedItem.Item_Desc
          );

          if (
            lowerCase(values.saleType) !== "tons" &&
            lowerCase(values.saleType) !== "pieces"
          ) {
            measurement = {
              Quantity: 1,
            };
          }

          if (lowerCase(values.saleType) === "tons" && !measurement) {
            return toast.error("This item is not sold in Tons");
          }

          const priceSoldPerUnit =
            lowerCase(values.saleType) === "tons"
              ? currency(values.PriceSold)
                  .divide(measurement?.Quantity || 1)
                  .format()
              : values.PriceSold;

          for (let [index, batch] of selectedItem.batches.entries()) {
            const pcsUnitCost = currency(batch.UnitCost, {
              symbol: "",
              separator: "",
            })
              .divide(measurement?.Quantity || 1)
              .format();

            // console.log(selectedItem);

            //
            const {
              calculatedMarkUp,
              calculatedIncentive,
            } = calculateMarkUp_Incentive_Margin({
              ...selectedItem,
              UnitCost: pcsUnitCost,
              PriceSold: priceSoldPerUnit,
              /*  Margin,
          marginType,
          marginPercentage,
          Incentive,
          incentiveType,
          incentivePercentage,
          markUp,
          markUpType,
          markUpPercentage, */
            });

            if (Number(batch.Quantity) >= Number(remainder)) {
              // means we are at the last
              updatedBatchDataToSave.push({
                unitCost: pcsUnitCost,
                quantity: remainder,
                totalUnitCost: currency(pcsUnitCost, {
                  symbol: "",
                  separator: "",
                })
                  .multiply(remainder)
                  .format(),
                totalUnitPrice: currency(priceSoldPerUnit, {
                  symbol: "",
                  separator: "",
                })
                  .multiply(remainder)
                  .format(),
                totalMargin: currency(selectedItem?.Margin || 0)
                  .divide(measurement?.Quantity || 1)
                  .multiply(remainder).value,
                totalIncentive: currency(calculatedIncentive || 0)
                  .divide(measurement?.Quantity || 1)
                  .multiply(remainder).value,
                totalMarkUp: currency(calculatedMarkUp || 0).multiply(remainder)
                  .value,
              });

              if (lowerCase(values.saleType) === "pieces") {
                values.UnitCost = pcsUnitCost;
              }
              break;
            } else {
              updatedBatchDataToSave.push({
                unitCost: pcsUnitCost,
                quantity: batch.Quantity,
                totalUnitCost: currency(pcsUnitCost, {
                  symbol: "",
                  separator: "",
                })
                  .multiply(batch.Quantity)
                  .format(),
                totalUnitPrice: currency(priceSoldPerUnit, {
                  symbol: "",
                  separator: "",
                })
                  .multiply(batch.Quantity)
                  .format(),
                totalMargin: currency(selectedItem?.Margin || 0)
                  .divide(measurement?.Quantity || 1)
                  .multiply(batch.Quantity).value,
                totalIncentive: currency(calculatedIncentive || 0)
                  .divide(measurement?.Quantity || 1)
                  .multiply(batch.Quantity).value,
                totalMarkUp: currency(calculatedMarkUp || 0).multiply(
                  batch.Quantity
                ).value,
              });

              remainder = Number(
                currency(remainder, {
                  symbol: "",
                  separator: "",
                })
                  .subtract(batch.Quantity)
                  .format()
              );
            }
          }

          //  console.log(updatedBatchDataToSave);

          const totalProfit = updatedBatchDataToSave
            .map((el) =>
              currency(el.totalUnitPrice)
                .subtract(el.totalUnitCost)
                .subtract(el.totalIncentive)
                .format()
            )
            .reduce(
              (a, b) =>
                currency(a, {
                  precision: 2,
                }).add(b),
              0
            );

          values.Profit = totalProfit;
          //-------------------------------------------------------------------------------------------------

          //---------------------------------
          values.Total_Incentive = updatedBatchDataToSave
            .map((el) => el.totalIncentive)
            .reduce(
              (a, b) =>
                currency(a, {
                  precision: 2,
                }).add(b),
              0
            );

          //  For Service Overwrite ----------------------------------------------------------
          if (lowerCase(selectedItem.Item_Type) === "service") {
            values.Profit = currency(priceSoldPerUnit)
              .subtract(selectedItem.UnitCost)
              .multiply(values.Quantity).value;
            values.Total_Incentive = currency(selectedItem?.Incentive).multiply(
              values.Quantity
            ).value;
          }

          return {
            ...selectedItem,
            Serial_Number: values?.Size ? values.Size : selectedItem.Item_Desc,
            ...values,
            UnitCost: !isEmpty(updatedBatchDataToSave)
              ? updatedBatchDataToSave[0]?.unitCost
              : selectedItem.UnitCost,
          };
        });

        const foundError = itemsForSale.find((el) => el.error);
        if (foundError) {
          toast.error(foundError.error);
          return;
        }

        //  console.log(itemsForSale, formik.values);
        handleAddItem(itemsForSale);
      } catch (err) {
        console.log(err);
        toast.error("Unable to get batches, Check connection and try again");
      }
    },
  });

  const [selectedItem, setselectedItem] = useState(null);
  //  const [searchString, setSearchString] = useState("");
  // const debouncedSearchString = useDebounce(searchString, 800);

  const getItems = async (debouncedqueryParams) => {
    if (generalSettings?.itemStatusSettings) {
      debouncedqueryParams.ItemStatus = "Processed";
    }
    const url = Warehouse ? `${itemsApiUrl}/items/${Warehouse}` : itemsApiUrl;
    let response = await fetch(
      `${backendUrl}${url}?${queryString.stringify(debouncedqueryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.category = [
      {
        label: "All",
        value: "",
      },
      ...data.category.map((el) => ({
        label: el.Cat_Name,
        value: el.Cat_Name,
      })),
    ];

    data.product = [
      {
        label: "All",
        value: "",
      },
      ...data.product.map((el) => ({
        label: el.Product_Name,
        value: el.Product_Name,
      })),
    ];
    return data;
  };

  const { data = { items: [], warehouses: [] }, isFetching } = useQuery(
    [queryActions.GET_ITEMS, debouncedqueryParams],
    () => getItems(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const getBatches = async (items) => {
    const url = Warehouse
      ? `${itemsApiUrl}/batches-for-sales/${Warehouse}/multiple?itemCodes=${items
          .map((el) => el.Bar_Code)
          .join(",")}`
      : `${itemsApiUrl}/batches-for-sales/multiple?itemCodes=${items
          .map((el) => el.Bar_Code)
          .join(",")}`;

    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        let response = await fetch(`${backendUrl}${url}`, {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        });
        if (!response.ok) {
          response = await response.json();
          throw new Error();
        } else {
          const { data } = await response.json();
          resolve(data);
        }
      } catch (err) {
        console.log(err);
        toast.error("Unable to load batches, Try again");
        reject();
      } finally {
        setIsLoading(false);
      }
    });
  };

  const handleSelectedItem = (item) => {
    //  console.log(selectedItem);
    // Check Customer type to get correct unit price
    const transType = lowerCase(selectedCustomer?.TransType);
    item.UnitPrice =
      transType === "distributor"
        ? item.Vat_5
        : ["wholesaler", "wholeseler"].includes(transType)
        ? item.Tax
        : item.UnitPrice;

    // Use Customer Types if available for customer
    const foundPriceForCustomerTransType = item?.Items_ItemPriceByCustomerType.find(
      (el) => selectedCustomer?.TransType === el.TransType
    );

    if (!isEmpty(foundPriceForCustomerTransType)) {
      item.UnitPrice = foundPriceForCustomerTransType.UnitPrice;
      if (Number(foundPriceForCustomerTransType.UnitPricePcs) > 0) {
        item.Tax = foundPriceForCustomerTransType.UnitPricePcs;
      }
    }

    item.unitPriceInTons = item.UnitPrice;
    item.unitPriceInPcs = item.Tax;

    if (transType === "distributor") {
      item.unitPriceInPcs = item.pcsPriceDistributor;
    }

    item.quantityInStock = item.Quantity;
    item.selectedItem = { ...item };
    const UnitPrice = currency(item.UnitPrice).value;

    item.UnitPrice = UnitPrice;
    item.PriceSold = UnitPrice;
    item.Discount = 0;
    item.saleType = item?.Product_Model ? item?.Product_Model : "Each";
    return item;
  };

  const handleSaleType = ({ item, saleType }) => {
    const editedTable = tableData.map((el) =>
      el.Bar_Code === item.Bar_Code
        ? {
            ...el,
            saleType,
            UnitPrice:
              lowerCase(saleType) === "tons"
                ? el.unitPriceInTons
                : lowerCase(saleType) === "pieces"
                ? el.unitPriceInPcs
                : el.UnitPrice,
            PriceSold:
              lowerCase(saleType) === "tons"
                ? el.unitPriceInTons
                : lowerCase(saleType) === "pieces"
                ? el.unitPriceInPcs
                : el.UnitPrice,
          }
        : { ...el }
    );

    setTableData([...editedTable]);
  };

  useEffect(() => {
    const finalSellingPrice = currency(formik.values.UnitPrice, {
      symbol: "",
      separator: "",
    })
      .subtract(formik.values.Discount)
      .format();
    formik.setFieldValue("PriceSold", finalSellingPrice);
  }, [formik.values.Discount]);

  // set quantity
  useEffect(() => {
    // if (selectedItem?.Item_Desc) {
    const quantity =
      lowerCase(formik.values.saleType) === "tons" && selectedItem?.Item_Desc
        ? tonsToPcs(
            formik.values.ironRodQuantity,
            selectedItem.Item_Desc,
            itemMeasurements
          )
        : formik.values.ironRodQuantity;
    formik.setFieldValue("Quantity", quantity);
    //}
  }, [
    formik.values.ironRodQuantity,
    formik.values.saleType,
    selectedItem?.Item_Desc,
    itemMeasurements,
  ]);

  const tableData = useMemo(() => {
    return cloneDeep(formik.values.itemsForSale);
  }, [formik.values?.itemsForSale]);

  const setTableData = (items) => {
    items = items.map((el) => ({
      ...el,
      SubTotal: currency(el.Quantity).multiply(el.PriceSold).value,
    }));
    formik.setFieldValue("itemsForSale", [...items]);
  };

  const addToCart = (selectedItem) => {
    if (tableData.find((el) => el.Bar_Code === selectedItem.Bar_Code)) {
      return;
    }
    const item = handleSelectedItem(cloneDeep(selectedItem));

    let saleType = item.Product_Model;
    if (item.Product_Model === "Tons") {
      // check if has enough tons
      const foundMeasurement = itemMeasurements.find(
        (measurement) => measurement.Size === selectedItem.Item_Desc
      );
      if (foundMeasurement) {
        const quantityInTon = Number(foundMeasurement.Quantity);

        if (Number(quantityInTon) > Number(item.quantityInStock)) {
          saleType = "Pieces";
        }
      }
    }

    const itemToAdd = {
      ...item,
      Quantity: 1,
      durationNumber: 0,
      durationValue: "Month",
      Warranty: false,
      requirePermission: false /* lowerCase(selectedItem.Cat_Name).includes("food") */,
      Size: selectedItem.Item_Desc,
      Serial_Number: selectedItem.Item_Desc,
      selectedBatches: [],
      saleType,
    };

    //  console.log(itemToAdd);
    setTableData([...tableData, itemToAdd]);
  };

  const removeFromCart = (item) => {
    setTableData([...tableData.filter((el) => el.Bar_Code !== item.Bar_Code)]);
  };

  const cartData = useCallback(
    (item) => {
      // console.log(tableData);
      return tableData.find((el) => el.Bar_Code === item.Bar_Code);
    },
    [tableData]
  );

  const addQuantityToCart = ({ item, Quantity }) => {
    setTableData(
      tableData
        .map((el) =>
          el.Bar_Code === item.Bar_Code
            ? {
                ...el,
                Quantity: Number(Quantity),
              }
            : { ...el }
        )
        .filter((el) => el.Quantity)
    );
  };

  /* const addPriceToCart = ({ item, PriceSold }) => {
    setTableData(
      tableData.map((el) =>
        el.Bar_Code === item.Bar_Code
          ? {
              ...el,
              PriceSold,
            }
          : { ...el }
      )
    );
  }; */

  const getItemImageUrl = (item) => {
    const images = item.images ? JSON.parse(item.images) : [];
    return !isEmpty(images)
      ? `${backendUrl}${itemsApiUrl}/view-file/${images[0]?.systemFileName}`
      : lowerCase(item.Cat_Name).includes("food")
      ? foodImageUrl
      : lowerCase(item.Cat_Name).includes("drinks")
      ? drinksImageUrl
      : noImageUrl;
  };

  const saveOrder = async () => {
    formik.setFieldValue("saveOrder", true);
    await waitFor(10);
    formik.submitForm();
  };

  const allItems = useMemo(() => {
    return Warehouse ? data?.warehouses : data?.items;
  }, [Warehouse, data?.warehouses, data?.items]);

  return (
    <>
      <Modal
        show={true}
        onHide={() => setShowItemSelectorModal(false)}
        dialogClassName="multi-item-select-modal inventory-modal-grid"
        backdropClassName={`global-backdrop`}
        //  centered={true}
        animation={false}
        enforceFocus={false}
        backdrop="static"
        fullscreen
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>Add Items</h1>
            <p>Select items and set the pricing details.</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            {/*   <h2>Select Items - {selectedCustomer?.TransType}</h2> */}

            <div className="items-table-area rounded">
              <div className="row gap-0 w-100 align-items-center">
                <div className="global-search-area m-3 mt-2 mx-0 col-4">
                  {!queryParams.q ? (
                    <MagnifyIcon />
                  ) : (
                    <CloseIcon
                      className="p-cursor"
                      onClick={() =>
                        setQueryParams({
                          ...queryParams,
                          q: "",
                          page: 1,
                        })
                      }
                    />
                  )}
                  <input
                    className="form-control search-input text-xl"
                    name="q"
                    value={queryParams.q}
                    onChange={(e) =>
                      setQueryParams({
                        ...queryParams,
                        q: e.target.value,
                        page: 1,
                      })
                    }
                    placeholder="Search item..."
                    // autoFocus
                    autoComplete="off"
                    style={{ fontSize: "1.15rem" }}
                  />
                </div>

                <Form.Group className="col-1">
                  <Form.Check
                    type="switch"
                    label={"Menu"}
                    id="Menu"
                    name="restaurantMenu"
                    className="custom-form-check mb-3"
                    checked={queryParams.restaurantMenu}
                    onChange={(e) =>
                      setQueryParams({
                        ...queryParams,
                        restaurantMenu: !queryParams?.restaurantMenu,
                        withQuantity: queryParams?.restaurantMenu,
                        page: 1,
                      })
                    }
                    style={{ fontSize: "1.15rem" }}
                  />
                </Form.Group>

                <Form.Group className="col-2">
                  <Select
                    classNamePrefix="form-select-large"
                    isSearchable={false}
                    placeholder="Sort By"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    options={sortOptions}
                    value={sortOptions.find(
                      (el) => el.value === queryParams.sort
                    )}
                    onChange={({ value }) =>
                      setQueryParams({
                        ...queryParams,
                        page: 1,
                        sort: value,
                      })
                    }
                  />
                </Form.Group>

                <Form.Group className="col-2">
                  <Form.Label className="d-none">Brand</Form.Label>
                  <Select
                    classNamePrefix="form-select-large"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    placeholder="All Brand"
                    name="product"
                    isSearchable={true}
                    key={data?.product}
                    onChange={(selected) => {
                      setQueryParams({
                        ...queryParams,
                        product: selected?.value,
                      });
                    }}
                    value={(data?.product || []).find(
                      (el) => el.value === queryParams.product
                    )}
                    options={data?.product || []}
                    isClearable={true}
                  />
                </Form.Group>

                <Form.Group className="col-2">
                  <Form.Label className="d-none">Category</Form.Label>
                  <Select
                    classNamePrefix="form-select-large"
                    menuPosition="fixed"
                    menuPlacement="auto"
                    placeholder="All Categories"
                    name="category"
                    isSearchable={true}
                    key={data?.category}
                    onChange={(selected) => {
                      setQueryParams({
                        ...queryParams,
                        category: selected?.value,
                      });
                    }}
                    value={(data?.category || []).find(
                      (el) => el.value === queryParams.category
                    )}
                    options={data?.category || []}
                    isClearable={true}
                  />
                </Form.Group>

                <Form
                  className="col-1"
                  noValidate
                  onSubmit={formik.handleSubmit}
                >
                  <Button
                    type="button"
                    className="submit-btn text-nowrap"
                    onClick={() => {
                      formik.submitForm();
                    }}
                    style={{ fontSize: "1.15rem" }}
                  >
                    Add to Cart
                  </Button>
                </Form>
              </div>

              <div className="row">
                <div className="col-4">
                  <Table
                    borderless
                    hover
                    striped
                    responsive
                    className="product-table"
                  >
                    <thead className="sticky text-nowrap">
                      <tr>
                        <th />
                        <th scope="col">Item Name</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Unit Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData &&
                        tableData.map((el, index) => (
                          <tr className="p-cursor" key={index}>
                            <td onClick={() => removeFromCart({ ...el })}>
                              <Button variant="" className="p-0">
                                <DeleteIcon color={"#f26e6e"} />
                              </Button>
                            </td>
                            <td title={el?.Quantity}>{el.Item_Name}</td>
                            <td>
                              <NumberCustomInput
                                name={`${el.Bar_Code}Quantity`}
                                style={{ width: "2.5rem" }}
                                value={cartData(el).Quantity}
                                onValueChange={(value, name) => {
                                  addQuantityToCart({
                                    item: el,
                                    Quantity: value,
                                  });
                                }}
                              />
                            </td>
                            <td>
                              {currency(el.PriceSold, {
                                precision: 2,
                                symbol: "",
                              }).format()}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot className="border-top border-bottom">
                      <tr>
                        <td />
                        <td colSpan={2}>
                          <b>Total</b>
                        </td>
                        <td>
                          <b>
                            {" "}
                            {currency(
                              tableData
                                .map((el) => el.SubTotal)
                                ?.reduce((a, b) => currency(a).add(b).value, 0),
                              {
                                precision: 2,
                                symbol: "",
                              }
                            ).format()}
                          </b>
                        </td>
                      </tr>
                    </tfoot>
                  </Table>{" "}
                  {isEmpty(tableData) ? (
                    <div className="no-item my-4 w-100 text-center">
                      <div className="info">
                        <NoSelectedItemIcon />
                        <h2 className="mt-3">Haven't selected an item yet</h2>
                        <p>....</p>
                      </div>
                    </div>
                  ) : (
                    <div>
                      {" "}
                      <Form.Group className="mb-3 align-items-center">
                        <Form.Label className="mb-1 text-nowrap fw-bold">
                          Table No. <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="ShipTo"
                          placeholder="Enter Table No."
                          value={formik.values.ShipTo}
                          onChange={formik.handleChange}
                          isInvalid={
                            formik.touched.ShipTo && !!formik.errors.ShipTo
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          {formik.errors.ShipTo}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Button
                        type="button"
                        className=" submit-btn"
                        onClick={() => saveOrder()}
                        style={{ fontSize: "1.15rem" }}
                      >
                        Save Order
                      </Button>
                    </div>
                  )}
                </div>

                <div className="col">
                  <div className="items-grid">
                    {allItems &&
                      (!showSelected ? allItems : tableData).map(
                        (item, index) => (
                          <div
                            key={item.Bar_Code}
                            className={`item-holder border p-cursor ${
                              Boolean(cartData(item)) ? "border-info" : ""
                            }`}
                          >
                            <div
                              onClick={() => {
                                if (!Boolean(cartData(item))) {
                                  addToCart({ ...item });
                                } else {
                                  addQuantityToCart({
                                    item,
                                    Quantity: currency(
                                      cartData(item).Quantity
                                    ).add(1).value,
                                  });
                                }
                              }}
                              style={
                                !Boolean(cartData(item))
                                  ? { minHeight: "100%" }
                                  : {}
                              }
                            >
                              <div className="image-area">
                                <img src={getItemImageUrl(item)} />
                              </div>
                              <h3 className="item-name">
                                {item.Item_Name || "..."}
                              </h3>
                              <p className="price">
                                {currency(item?.UnitPrice, {
                                  symbol: getCurrencySymbol(
                                    generalSettings?.prevailingCurrency
                                  ),
                                }).format()}
                              </p>{" "}
                            </div>
                            {Boolean(cartData(item)) ? (
                              <NumberCustomInput
                                // name={`${item.Bar_Code}Quantity`}
                                value={cartData(item).Quantity}
                                onValueChange={(value, name) => {
                                  addQuantityToCart({
                                    item,
                                    Quantity: value,
                                  });
                                }}
                                onClick={(e) => e.stopPropagation()}
                              />
                            ) : null}
                          </div>
                        )
                      )}
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end gap-4 align-items-center my-3">
                <Button
                  type="button"
                  className=" submit-btn"
                  onClick={() => {
                    formik.submitForm();
                  }}
                  style={{ fontSize: "1.15rem" }}
                >
                  Add to Cart
                </Button>
              </div>
              {/*   <hr /> */}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {!showSelected && (
            <div className="d-flex justify-content-between px-3 my-3 align-items-center pagination gap-4">
              <p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
              </p>

              <ReactPaginate
                {...paginationOptions}
                pageCount={Math.ceil(data.count / queryParams.limit)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={0}
                onPageChange={({ selected }) => {
                  document.body.scrollTop = document.documentElement.scrollTop = 0;
                  setQueryParams({
                    ...queryParams,
                    page: selected + 1,
                  });
                }}
                forcePage={queryParams.page - 1}
              />
            </div>
          )}
        </Modal.Footer>
      </Modal>
      <ModalLoader
        show={isLoading}
        onExited={() => scrollToElement('input[name="ironRodQuantity"]')}
      />
    </>
  );
}
